<template>
  <div class="main-body">
    <div class="table-operator">
      <a-button type="primary" @click="showBackup()"
        ><PlusOutlined />点击备份</a-button
      >
    </div>
    <a-table
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :columns="columns"
      :data-source="data"
      :pagination="false"
    />
  </div>
</template>

<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  ref,
} from "vue";
import * as systerm from "@/addons/manage/api";
const columns = [
  {
    title: "表名",
    dataIndex: "Name",
  },
  {
    title: "数据量",
    dataIndex: "Rows",
  },
  {
    title: "数据大小",
    dataIndex: "Data_length",
  },
  {
    title: "创建时间",
    dataIndex: "Create_time",
  },
];

export default defineComponent({
  components: {
    PlusOutlined,
  },
  setup() {
    const data = ref([]);
    const state = reactive({
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
    });
    const hasSelected = computed(() => state.selectedRowKeys.length > 0);

    const start = () => {
      state.loading = true;
      // ajax request after empty completing
      setTimeout(() => {
        state.loading = false;
        state.selectedRowKeys = [];
      }, 1000);
    };
    const onSelectChange = (selectedRowKeys) => {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      state.selectedRowKeys = selectedRowKeys;
    };
    const handleRefresh = () => {
      systerm.database().then((res) => {
        data.value = res.data;
      });
    };
    onMounted(() => {
      handleRefresh();
    });
    return {
      data,
      columns,
      hasSelected,
      ...toRefs(state),

      // func
      start,
      onSelectChange,
    };
  },
});
</script>
